import { EnvironmentConfiguration } from './environment-types';

const appUri: string = 'https://w1vmgmt-qa.awapps.com';

export const environment: EnvironmentConfiguration = {
  production: true,
  baseUri: appUri,
  deploymentEnvironment: 'qa',
  version: '2024-08-30+12694_qa',
  apiBaseUrl: 'https://w1v-qa.awapps.com/core',
  okta: {
    clientId: '0oawz5miaokDNqd2j0h7',
    issuer: 'https://login-dev.amwater.net/oauth2/aust4s51xcphZQULl0h7',
    redirectUri: `${appUri}/login/callback`,
    scopes: ['openid', 'profile', 'email','W1V'],
    pkce: true,
  },
  w1vApi:{
    apikey:"5EMvcGuBGARGgQNhGRIp52FhrfOkKV1Q",
    baseUrl:"https://w1v-qa.awapps.com/core",
    originalUrl:'https://w1v-qa.awapps.com',
    sapUrl:"https://awqas.test.apimanagement.us10.hana.ondemand.com:443/",
    c1vUrl: "https://c1v-qa.awapps.com/openidlogin",
    meterOpsUrl: 'https://meteropaws.amwaternp.net/',
  },
  userRoles:{
    FRCC:"w1v_frcc",
    AW_SUPERVISOR:"W1V_SUP",
   CONT_SUPERVISOR:"W1V_CONTSUP",
   ADMIN:"W1V_POWERUSER"
  }
};
